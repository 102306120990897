"use client";

import { useUserSubscription } from "@/services/payment";
import { getFullName } from "@/services/user";
import { SubscriptionPlans } from "@/types/api";
import { useStytchUser } from "@stytch/nextjs";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { trackEvent } from "./mixpanel";
import { vercelEnv } from "@/services/environment";

const isProduction = vercelEnv === "production";

export default function MixPanelAnalytics() {
    const { user, isInitialized } = useStytchUser();
    const { data: subscription } = useUserSubscription();

    const fullName = getFullName(user?.name);
    const email = user?.emails[0]?.email;
    const userIsPro = subscription?.subIsActive;

    const trackUserOnline = () => {
        trackEvent("User Online", {
            user_id: user?.user_id,
            timestamp: new Date().toISOString(),
        });
    };

    useEffect(() => {
        if (!isInitialized || !isProduction) return;

        mixpanel.identify(user?.user_id);
        mixpanel.people.set({
            $name: fullName,
            $email: email,
            plan: userIsPro ? SubscriptionPlans.Pro : SubscriptionPlans.Basic,
            // Add anything else about the user here
        });

        // Send event when user visits the site
        trackUserOnline();

        // Send event every 30 seconds to maintain "active" status
        const interval = setInterval(trackUserOnline, 30000);

        return () => clearInterval(interval);
    }, [isInitialized, userIsPro]);

    return <></>;
}
