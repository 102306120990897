"use client";

import MemoCopy from "@assets/SVG/Copy";
import { EyeIcon, EyeSlashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CSSProperties, Dispatch, SetStateAction, useState } from "react";
import "./input.css";

export default function Input({
    input,
    label,
    setInput,
    disabled,
    error = "",
    placeholder,
    type = "text",
    className = "",
    name,
    maxLength,
    showEyeIcon,
    showCopyIcon = true,
    showDeleteIcon = true,
    autoComplete,
    style,
}: {
    input: string | number;
    label?: string;
    setInput: Dispatch<SetStateAction<string>> | Dispatch<SetStateAction<number>>;
    disabled?: boolean;
    error?: string;
    placeholder?: string;
    name?: string;
    type?: string;
    className?: string;
    maxLength?: number;
    showEyeIcon?: boolean;
    showCopyIcon?: boolean;
    showDeleteIcon?: boolean;
    autoComplete?: string;
    style?: CSSProperties | undefined;
}) {
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [localType, setLocalType] = useState(type);

    const toggleVisibility = () => {
        setPasswordVisibility(!passwordVisibility);

        if (localType !== "password") {
            setLocalType("password");
        } else setLocalType("text");
    };
    return (
        <div className="relative flex w-full flex-col items-center justify-center gap-2">
            {label && <label className="mt-2 self-start text-xs font-light text-neutral">{label}</label>}
            <div className="relative w-full items-center">
                <input
                    type={localType}
                    name={name}
                    placeholder={placeholder || "Type here"}
                    className={`${className} ${
                        error && "border-light-red"
                    } background-black input size-full overflow-hidden outline-0 focus:outline-0`}
                    onChange={(e) => setInput(e.target.value as any)}
                    value={input}
                    disabled={disabled ? true : false}
                    maxLength={maxLength}
                    autoComplete={autoComplete}
                    style={style}
                />
                {showEyeIcon && (
                    <div
                        className="absolute inset-y-0 right-3 m-auto flex cursor-pointer items-center text-neutral hover:brightness-75"
                        onClick={toggleVisibility}
                    >
                        {passwordVisibility ? (
                            <EyeSlashIcon className="size-3" />
                        ) : (
                            <EyeIcon className="size-3" />
                        )}
                    </div>
                )}

                {type !== "password" && input && (
                    <div className="absolute inset-y-0 right-4 m-auto flex cursor-pointer items-center text-neutral hover:brightness-75">
                        {showDeleteIcon ? (
                            <XMarkIcon
                                className="text-neutral"
                                onClick={() => setInput(typeof input === "string" ? "" : (0 as any))}
                            />
                        ) : (
                            showCopyIcon && <MemoCopy className="size-3.5" />
                        )}
                    </div>
                )}
            </div>

            {error && (
                <div className="flex w-full items-center gap-2 p-0">
                    <div className="h-1.5 rounded-full bg-light-red" />
                    <p className="text-[10px] font-light leading-3 text-light-red">{error}</p>
                </div>
            )}
        </div>
    );
}
