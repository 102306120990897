// Analytics.tsx

import { vercelEnv } from "@/services/environment";
import Script from "next/script";

const isProduction = vercelEnv === "production";

export default function GoogleAnalytics() {
    if (!isProduction) {
        return null;
    }

    return (
        <>
            <Script
                src="https://www.googletagmanager.com/gtag/js?id=G-H8GYLEBR8D"
                strategy="afterInteractive"
            />
            <Script
                id="google-script"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'G-H8GYLEBR8D');
    `,
                }}
            />
        </>
    );
}
