"use client";

import { StytchProvider } from "@stytch/nextjs";
import { createStytchUIClient } from "@stytch/nextjs/ui";
// import { StatsigProvider } from "statsig-react";
import { EventsContextProvider } from "@app/dashboard/EventsContext";
import { VolumeContextProvider } from "./dashboard/VolumeContext";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "@/services/queryclient";
import { vercelEnv } from "@/services/environment";

const isProduction = vercelEnv === "production";
// const tier = isProduction ? "production" : "development";

export function Providers({ children }) {
    const public_token = isProduction
        ? process.env.NEXT_PUBLIC_STYTCH_PUBLIC_TOKEN_PROD
        : process.env.NEXT_PUBLIC_STYTCH_PUBLIC_TOKEN_DEV;
    const stytch = createStytchUIClient(public_token as string);
    return (
        // <StatsigProvider
        //     sdkKey="client-Gok6GKwsPAmn1xdhi2oYB0Kja78fh5cEB4k8VxmWQQH"
        //     waitForInitialization={false}
        //     options={{ environment: { tier } }}
        //     user={{}}
        // >
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <StytchProvider stytch={stytch}>
                <EventsContextProvider>
                    <VolumeContextProvider>{children}</VolumeContextProvider>
                </EventsContextProvider>
            </StytchProvider>
        </QueryClientProvider>
        // </StatsigProvider>
    );
}
