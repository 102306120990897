import { vercelEnv } from "@/services/environment";
import { useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

const isProduction = vercelEnv === "production";
const host = isProduction ? "prod" : "dev";

export default function useSocketEvents() {
    const [socket, setSocker] = useState({} as Socket);

    useEffect(() => {
        const s = io(`https://api-${host}.blockbeat.io`);

        s.on("connect", () => {
            // console.log(`Connected to ${host} Events websocket`);
            setSocker(s);
        });

        return () => {
            s.disconnect();
        };
    }, []);

    return socket;
}
