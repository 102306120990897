import { VolumeContextValue } from "@/types/util";
import { createContext, useContext, useState } from "react";

const VolumeContext = createContext<VolumeContextValue | undefined>(undefined);

const VolumeContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [volume, setVolume] = useState(0.5);

    const value: VolumeContextValue = {
        volume,
        setVolume,
    };

    return <VolumeContext.Provider value={value}>{children}</VolumeContext.Provider>;
};

const useVolume = () => {
    const context = useContext(VolumeContext);
    if (!context) {
        throw new Error("useVolumeContext must be used within a VolumeContextProvider");
    }
    return context;
};

export { useVolume, VolumeContextProvider };
