"use client";

import { Analytics as VercelAnalytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import GoogleAnalytics from "@app/analytics/GoogleAnalytics";
import FacebookAnalytics from "@app/analytics/FacebookAnalytics";
import initMixPanel from "@app/analytics/mixpanel";
import { useEffect } from "react";
import { vercelEnv } from "@/services/environment";

const isProduction = vercelEnv === "production";

export default function Analytics() {
    useEffect(() => {
        if (isProduction) {
            initMixPanel(); // Initialize Mixpanel
        }
    }, []);

    return (
        <>
            <SpeedInsights />
            <VercelAnalytics />
            <GoogleAnalytics />
            <FacebookAnalytics />
        </>
    );
}
