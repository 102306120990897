"use client";

import { Fragment, useEffect, useState } from "react";
import { Bars2Icon, CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import MemoFullBlockbeatLogo from "@assets/SVG/Logo/FullBlockbeatLogo";
import Input from "@form-elements/Input";
import { Button } from "@form-elements/Button";
import Image from "next/image";
import BlockbeatDashboard from "@assets/Images/BlockBeat_Dashboard_Picture.jpg";
import { viewOnDesktopEmail } from "@/services/email";
import { usePathname } from "next/navigation";
import { NextFont } from "next/dist/compiled/@next/font";
import { emailValidator } from "@/types/util";

export default function MobileView({ font }: { font: NextFont }) {
    const [open, setOpen] = useState(false);

    const [email, setEmail] = useState("");

    const [emailError, setEmailError] = useState("");

    const [loading, setLoading] = useState(false);

    const [success, setSuccess] = useState(false);

    const pathname = usePathname();

    const sendMobileEmail = (e) => {
        e.preventDefault();

        if (!email || !!emailError || loading || success) {
            return;
        }

        setLoading(true);
        const href = window.location.origin + pathname;

        try {
            viewOnDesktopEmail({
                to: email,
                href: href,
                origin: window.location.origin,
            })
                .then(() => {
                    setSuccess(true);
                    setLoading(false);
                    setTimeout(function () {
                        setEmail("");
                        setSuccess(false);
                    }, 3000);
                })
                .catch(() => {
                    setSuccess(false);
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
            setSuccess(false);
        }
    };

    useEffect(() => {
        emailValidator(email, setEmailError);
    }, [email]);

    const navigation = [
        {
            name: "Home",
            href: "https://www.blockbeat.io",
        },
        { name: "Solutions", href: "https://www.blockbeat.io/#access" },
        { name: "Contact", href: "https://www.blockbeat.io/#contact" },
    ];

    return (
        <div className={font.className}>
            <header className="relative flex items-center justify-between px-6 py-[22px]">
                <MemoFullBlockbeatLogo className="h-7 w-[154px]" />

                <div className="absolute inset-y-0 right-0 flex items-center pr-4">
                    <button
                        type="button"
                        className="-mr-2 inline-flex items-center justify-center rounded-md p-2 text-white outline-0 hover:brightness-75 focus:outline-0"
                        onClick={() => setOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars2Icon className="block size-6" aria-hidden="true" />
                    </button>
                </div>

                {open && (
                    <nav
                        className="fixed inset-0 z-40 size-full bg-dark-red px-6 py-[22px] sm:inset-y-0 sm:left-auto sm:right-0 sm:w-full sm:max-w-sm sm:shadow-lg"
                        aria-label="Global"
                    >
                        <div className="flex h-16 items-center justify-between">
                            <div className="items-center">
                                <MemoFullBlockbeatLogo className="h-7 w-[154px]" />
                            </div>
                            <button
                                type="button"
                                className="-mr-2 inline-flex items-center justify-center rounded-md"
                                onClick={() => setOpen(false)}
                            >
                                <span className="sr-only">Close main menu</span>
                                <XMarkIcon className="block size-6" aria-hidden="true" />
                            </button>
                        </div>

                        <div className="mt-12 flex flex-col gap-10">
                            {navigation.map((item) => (
                                <Fragment key={item.name}>
                                    <a
                                        href={item.href}
                                        className="text-2xl font-light leading-[29px] tracking-[-0.02em] text-white hover:brightness-75"
                                    >
                                        {item.name}
                                    </a>
                                </Fragment>
                            ))}
                        </div>
                    </nav>
                )}
            </header>

            <div className="flex h-[calc(100vh-72px)] flex-col overflow-hidden">
                <form
                    onSubmit={sendMobileEmail}
                    className="mb-3 mt-12 flex flex-col items-center gap-4 p-0 px-6"
                >
                    <p
                        style={{
                            background:
                                "linear-gradient(93.99deg, #0085FF 10.23%, #5EDAE2 56.41%, #C5FFB7 88.68%), linear-gradient(271.75deg, rgba(31, 56, 105, 0.44) -3.73%, rgba(31, 56, 105, 0) 79.77%), linear-gradient(216.45deg, #FFFFFF -5.16%, rgba(255, 255, 255, 0) 75.23%), #FFFFFF",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                            backgroundClip: "text",
                            color: "transparent",
                        }}
                        className="flex items-center text-center text-[42px] font-medium leading-[48px] tracking-[-0.01em]"
                    >
                        Our Mobile App Will Be Out Soon!
                    </p>
                    <div className="flex flex-col gap-1.5 text-center text-base font-light leading-6 tracking-[-0.02em] text-white">
                        <p>Enter Your Email to Access BlockBeat</p>
                        <p>from Your Desktop or Laptop Computer</p>
                    </div>
                    <Input
                        className={`${
                            email && !emailError
                                ? "border-secondary"
                                : emailError
                                  ? "border-red-600"
                                  : "border-dark-blue"
                        } h-10 gap-2 rounded-lg border bg-steel-blue p-3 text-base text-light-blue`}
                        input={email}
                        setInput={setEmail}
                        placeholder="Your Email"
                        error={emailError}
                        autoComplete="email"
                    />
                    <Button
                        type="submit"
                        onClick={sendMobileEmail}
                        className={`${
                            loading
                                ? "justify-between bg-oxford-blue"
                                : success
                                  ? "justify-between bg-dark-green"
                                  : "justify-center bg-powder-blue"
                        } relative flex h-10 w-full items-center gap-2.5 rounded-lg border-none px-5`}
                        disabled={!email || !!emailError || loading || success}
                    >
                        <p className="text-xs font-normal text-white">
                            {loading ? "LOADING" : success ? "THANK YOU!" : "ACCESS BLOCKBEAT"}
                        </p>
                        {loading ? (
                            <div className="size-5 animate-spin rounded-full border border-solid border-white border-t-transparent"></div>
                        ) : (
                            success && <CheckCircleIcon className="size-5 text-white" />
                        )}
                    </Button>
                </form>
                <div className="relative flex grow overflow-hidden">
                    <div
                        style={{
                            background:
                                "radial-gradient(48.06% 44.37% at 50% 50%, rgba(45, 111, 243, 0.73) 4.6%, rgba(72, 123, 225, 0) 92.19%)",
                        }}
                        className="absolute -left-12 top-0 h-[409px] w-[369px]"
                    />

                    <div className="relative left-12 h-110 w-[620px]">
                        <Image
                            className="relative flex size-full grow object-contain"
                            src={BlockbeatDashboard}
                            alt="BlockBeat Dashboard Image"
                            fill
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
