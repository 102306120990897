import mixpanel from "mixpanel-browser";
import { vercelEnv } from "@/services/environment";

const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
const isProduction = vercelEnv === "production";

const initMixpanel = () => {
    if (!MIXPANEL_TOKEN) {
        console.warn("Mixpanel token is missing! Check your .env file.");
        return;
    }

    mixpanel.init(MIXPANEL_TOKEN, { autocapture: false });
};

export const trackEvent = (event = "", payload = {}) => {
    if (isProduction) {
        return mixpanel.track(event, payload);
    }
};

export default initMixpanel;
